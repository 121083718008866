import React from "react"
import Layout2 from "../components/layout2"
import ReactVivus from 'react-vivus';
import Button from './../elements/button.js'
import Disqus from './../elements/disqus.js'
import EmailSignup from './../components/emailsignup.js'
import HighsAndLows from "./../elements/highsandlows.js"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"
import PhotoGrid1 from './../components/photo_grid_1.js'
import PhotoGrid2 from './../components/photo_grid_2.js'
import Gif from './../img/about/gif/out.gif'
import OneGif from './../img/about/gif/1.gif'
import TwoGif from './../img/about/gif/2.gif'
import ThreeGif from './../img/about/gif/3.gif'
import './../css/about.css'


export default class Responsive extends React.Component {
  render(){
    return(
<Layout2>
<div css={{maxWidth:`700px`, marginLeft:`auto`, marginRight:`auto`, paddingLeft:`10px`, paddingRight:`10px`, '@media (max-width: 700px)': {paddingLeft:`5%`, paddingRight:`5%`, marginLeft:`10px`, marginRight:`10px`}}}>
<p css={{color:`#7d4aa3`, fontWeight:`500`, marginBottom:`1rem`, marginTop:`2rem`}}> About Me</p>
<h1>What's this all about?</h1>
    <div
    css = {{marginTop:`2rem`}}
      className="post-content">
      <p>Why hello there. How's it going? Welcome to the "about me" page.</p>
      <p>These pages are always quite tricky. More often than not, it is just a laundry list of achievements meant to make someone sound impressive. That's really not my style.</p><p>I thought long and hard about how I wanted to tell my story. After much deliberation, and a couple (cough cough 19) notebook sketches, I finally came up with something I am proud to call my own.</p>
      <h1>The essence of me</h1>
      <p>If you want to get a sense of what I am like, here's me in my element: states of deep work. <a href="https://glanceback.info/"><u>When I open a tab, sometimes it grabs a picture of me and I write what I am thinking about.</u></a> The result is a conglamoration of silliness that somehow manages to better encapsulate who I am than any instagram feed could ever do.</p>
      <img src={Gif}/>
      <h1>My vision for the world</h1>
      <p>All the work I do is centered around my vision for the world. <b>I envision a world where people:</b></p>
      <span css={{display:`flex`, marginBottom:`1rem`}}><img src={OneGif} css={{maxHeight:`50px`, marginLeft:`-20px`, marginBottom:`0`}}/><p css={{marginTop:`auto`, marginBottom:`auto`}}>Act with intentionality.</p></span>
      <span css={{display:`flex`, marginBottom:`1rem`}}><img src={TwoGif} css={{maxHeight:`50px`, marginLeft:`-20px`, marginBottom:`0`}}/><p css={{marginTop:`auto`, marginBottom:`auto`}}>Listen with curiosity.</p></span>
      <span css={{display:`flex`, marginBottom:`1rem`}}><img src={ThreeGif} css={{maxHeight:`50px`, marginLeft:`-20px`, marginBottom:`0`}}/><p css={{marginTop:`auto`, marginBottom:`auto`}}>Build community through vulnerability.</p></span>
      <p></p>
      <h1>I believe failures are just as important as successes</h1>
      <p>Take a gander at some of my proudest moments and some of my moments of most epic failure :) </p>
      <HighsAndLows/>
      <h1>At the end of the day.</h1>
      <p>I am someone that never wants to lose the naviety that I can make a difference in the world. I only have one life. I will make it count.</p>
    </div>

    </div>
<br/>
<br/>

<div css = {{width:`100%`, margin:`auto`,marginBottom:`4rem!important`, textAlign:`center`}}>

</div>
<div css = {{width:`100%`, margin:`auto`, textAlign:`center`}}>
</div>


<Button to = "/" cta = "back to home"/>
<br/>
<br/>

</Layout2>);
}
}
