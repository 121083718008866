import React from "react"
// import Layout2 from "../components/layout2"
// import ReactVivus from 'react-vivus';
// import Button from './../elements/button.js'
// import Disqus from './../elements/disqus.js'
// import EmailSignup from './../components/emailsignup.js'
// import HighsAndLows from "./../elements/highsandlows.js"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default class Responsive extends React.Component {
  render(){
    return(
<div css={{'@media (max-width: 500px)':{gridTemplateColumns: `repeat(1, 1fr)`,marginTop:`10rem`}, '@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`, marginTop:`10rem`}, maxWidth:`700px`,marginLeft:`auto`,marginRight:`auto`, display:`grid`, gridTemplateColumns: `repeat(3, 1fr)`, gridGap: `1rem`, marginTop:`10rem`}}>

<StaticQuery
  query={graphql`{
    AboutPhotos:
        allFile(filter: {relativeDirectory: {eq: "img/about/good"}}, sort: {fields: name, order: DESC}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth:500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    }
    }
  `}
  render={data => (
    data.AboutPhotos.edges.map(({ node }, i) => (
      <Img css={{transform:parseInt(Math.random())==0 ? `scale(0.9)` : `scale(0.5)`}} fluid = {node.childImageSharp.fluid}> </Img>
    )
  )
)
}
/>
</div>
);
}
}
